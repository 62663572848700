import { useEffect, useState } from "react";
import PointAndText from "../PointAndText/PointAndText";
import styles from "./banner.module.css";

export default function Banner({ data }) {
  const { title, description, call } = data;
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.banner}>
      {!isSmallScreen ? (
        <>
          <PointAndText
            pointColor={"var(--color-accent)"}
            fontSize="14px"
            textColor={"white"}
            text={title}
          />
          <span className={`${styles.text} ${styles.text__main}`}>
            {description}
          </span>
          <div className={`${styles.text} ${styles.text__link}`}>{call}</div>
        </>
      ) : (
        ""
      )}
    </div>
  );
}
