import React from "react";
import { AnimatePresence, motion } from "framer-motion";
import styles from "./animatedbutton.module.css";

export const AnimatedButton = ({
  buttonColor,
  subscribeStatus,
  changeText,
  initialText,
  type = "button",
  disabled = false,
}) => {
  return (
    <AnimatePresence mode="wait">
      {subscribeStatus ? (
        <motion.button
          className={`${styles.button} ${styles.subscribedButton}`}
          type={type}
          disabled={disabled}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.span
            key="action"
            className={styles.span}
            initial={{ y: -50 }}
            animate={{ y: 0 }}
          >
            {changeText}
          </motion.span>
        </motion.button>
      ) : (
        <motion.button
          className={`${styles.button} ${styles.initialButton}`}
          style={{ backgroundColor: buttonColor }}
          type={type}
          disabled={disabled}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.span
            key="reaction"
            className={styles.span}
            initial={{ x: 0 }}
            exit={{ x: 50, transition: { duration: 0.3 } }}
          >
            {initialText}
          </motion.span>
        </motion.button>
      )}
    </AnimatePresence>
  );
};
