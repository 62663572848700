import styles from "./sportspage.module.css";
import Card from "../Card/Card";
import ChooseYourTeam from "../ChooseYourTeam/ChooseYourTeam";

/*
Props for the SportsPage component:

sportsPage (object):
  - backgroundUrl: string, the background image for the Backgrounds component
  - pageTitle: string, the title to be displayed in the page

sports (array):
  - text: string, the description text displayed under "Escoge tu grupo"
  - sportsArray: array, each object in the array contains:
      - url: string, image url for the card
      - sport: string, name of the sport
      - groups: string, the number of groups available for the sport

  - sportPopUp (object):
      - title: string, the title displayed in the popup
      - text: string, the main text displayed in the popup (supports line breaks)
      - mail: string, the email address displayed in the IconLabel component
      - popUpImage : string, image displayed on the component
      - mapUrl : string, location of the sport
      - mapProps (object):
          -mapUrl (string): Google Maps link to the sport location
          -mapHeight (string): height of the map
          -mapWidth (string): width of the map
      - sportInfoArray: array of arrays, each subarray represents a team and contains:
          - cabraColor : string, color of the cabra
          - source: string, URL of the team image
          - teamName: string, name of the team
          - teamLocation: string, location where the team trains (displayed in the table)
          - teamHours: string, training hours (displayed in the table)
          - teamWeek: array of booleans, availability on Monday to Friday

      Available colors for the cabraColor; enter the '#...'

          const colors = [
      { name: 'Sky Blue', value: '#a7c5ff' },
      { name: 'Peach', value: '#ff8b74' },
      { name: 'Lavender', value: '#dcb8ff' },
      { name: 'Pink', value: '#f6aee4' },
      { name: 'Sunshine', value: '#fddb77' },
      { name: 'Cherry', value: '#ff5c72' },
      { name: 'Teal', value: '#57d4c1' },
      { name: 'Aqua', value: '#64d0eb' }
*/

export default function SportsPage({ sports }) {
  return (
    <>
      <div className={styles.home}>
        {/*
        no lo uses que por ahora te petará
        <Backgrounds imageUrl={sportsPage.backgroundUrl}></Backgrounds>
        */}
        <h1>{sports.pageTitle}</h1>
      </div>
      <div>
        <Card>
          <ChooseYourTeam sports={sports} />
        </Card>
      </div>
    </>
  );
}
