import Home from "../Home/Home";
import Union from "../Union/Union";
import JoinCierzo from "../JoinCierzo/JoinCierzo";
import FindYourTeam from "../FindYourTeam/FindYourTeam";
import Card from "../Card/Card";

export default function LandingPage({ data = {} }) {
  return (
    <>
      <Home data={data.home} />
      <Union data={data.aboutUs} />
      <JoinCierzo joinCierzo={data.joinCierzo} />
      <Card id={"findYourTeam"} backgroundColor={"#FFF"}>
        <FindYourTeam findYourTeam={data.findYourTeam} />
      </Card>
    </>
  );
}
