import { useEffect, useState, useRef } from "react";

export default function Backgrounds({ imageUrl }) {

  const [landingHeight, setLandingHeight] = useState(vhToPixels(95));
  const [aboutUsHeight, setAboutUsHeight] = useState(0);
  const [joinCierzoHeight, setJoinCierzoHeight] = useState(0);
  const [findYourTeamHeight, setFindYourTeamHeight] = useState(0);
  const [juegosADCierzoHeight, setJuegosADCierzoHeight] = useState(0);
  const [isLoaded, setIsLoaded] = useState(false);

  const backgroundRef = useRef(null);
  const [fontsChecked, setFontsChecked] = useState(false);

  document.fonts.ready.then(function () {
    if (!fontsChecked) { // Mejor práctica: evitar doble verificación
      setFontsChecked(true);
      calculateBG();
    }
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsLoaded(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (backgroundRef.current) {
      observer.observe(backgroundRef.current);
    }

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", calculateBG);
    return () => window.removeEventListener("resize", calculateBG);
  }, []);

  function calculateBG() {
    console.log('Calculando fondo');

    const start = vhToPixels(100);
    const offset = window.scrollY;
    let res = vhToPixels(8);

    // Comprobación de existencia de la sección antes de calcular su tamaño
    const aboutUs = document.querySelector("#aboutUs");
    if (aboutUs) { // Solo se ejecuta si la sección existe
      const rect = aboutUs.getBoundingClientRect();
      const top = rect.top + offset;
      const height = rect.height;

      setAboutUsHeight(height - res * 2);
      setLandingHeight(top < start ? vhToPixels(95) : vhToPixels(95) + (top - start) + res);
    }

    // Verificación de existencia de la sección JoinCierzo y su imagen PNG
    const joinCierzo = document.querySelector("#joinCierzo");
    const joinCierzoPNG = document.querySelector("#joinCierzoPNG");
    if (joinCierzo && joinCierzoPNG) { // Solo se ejecuta si ambos elementos existen
      if (joinCierzoPNG.complete) {
        const rect = joinCierzo.getBoundingClientRect();
        setJoinCierzoHeight(rect.height + res * 2);
      } else {
        joinCierzoPNG.onload = calculateBG; // Recalcula cuando la imagen carga
      }
    }

    // Comprobación de existencia de FindYourTeam
    const findYourTeam = document.querySelector("#findYourTeam");
    if (findYourTeam) { // Solo se ejecuta si la sección existe
      const rect = findYourTeam.getBoundingClientRect();
      setFindYourTeamHeight(rect.height - res * 2);
    }

    // Comprobación de existencia de JuegosADCierzo
    const juegosADCierzo = document.querySelector("#juegosADCierzo");
    if (juegosADCierzo) { // Solo se ejecuta si la sección existe
      const rect = juegosADCierzo.getBoundingClientRect();
      setJuegosADCierzoHeight(rect.height + res * 2);
    }
  }

  function vhToPixels(vh) {
    const viewportHeight = window.innerHeight;
    return (vh / 100) * viewportHeight;
  }

  return (
    <div
      ref={backgroundRef}
      style={{
        top: "5vh",
        width: "100%",
        zIndex: "-1",
        position: "absolute",
      }}
    >
      {isLoaded ? (
        <div
          style={{
            backgroundImage: `url(${imageUrl})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            minHeight: `${landingHeight}px`,
          }}
        />
      ) : (
        <div style={{
          minHeight: `${landingHeight}px`,
          backgroundColor: "#e0e0e0"
        }} />
      )}

      {aboutUsHeight !== 0 && (
        <div
          style={{
            backgroundColor: "#F5F5F5",
            height: `${aboutUsHeight}px`,
          }}
        />
      )}

      {joinCierzoHeight !== 0 && (
        <div
          style={{
            backgroundColor: "#7994FF",
            height: `${joinCierzoHeight}px`,
          }}
        />
      )}

      {findYourTeamHeight !== 0 && (
        <div
          style={{
            backgroundColor: "#F5F5F5",
            height: `${findYourTeamHeight}px`,
          }}
        />
      )}

      {juegosADCierzoHeight !== 0 && (
          <div
            style={{
              backgroundImage: `linear-gradient(rgba(30, 20, 69, 0.6), rgba(30, 20, 69, 0.6)), url(/assets/webImages/BasketFieldBG.webp)`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: `${juegosADCierzoHeight}px`,
            }}
          />
      )}
    </div>
  );
}
