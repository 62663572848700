import { useRef } from "react";
import Transparencia from "../components/Transparencia/Transparencia";
import Banner from "../components/Banner/Banner";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Backgrounds from "../components/Backgrounds/Backgrounds";
import ContactForm from "../components/ContactForm/ContactForm";
import Logo from "../components/Logo/Logo";

export default function AvisoLegalPage({ data }) {
  const contactFormRef = useRef(null);

  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <main>
      <Backgrounds imageUrl={"/assets/webImages/JuegosDelCierzoInfoBG.webp"} />
      <Logo />
      <Banner data={data.banner} />
      <Header onContactClick={scrollToContactForm} />
      <Transparencia />
      <br />
      <div ref={contactFormRef}>
        <ContactForm />
      </div>
      <Footer footer={data.footer} />
    </main>
  );
}
