import styles from './avisolegal.module.css'
import Card from '../Card/Card';

export default function AvisoLegal() {


  return(<>
  <div className={styles.container}>
    <h1 className={styles.text}>AVISO LEGAL</h1>
  <Card>
    <div className={styles.content}>
    <p>
    Este sitio web (en adelante, el “Sitio Web”) pertenece y es gestionado por la Asociación Deportiva Cierzo proLGTB+ (en adelante, “A.D. Cierzo”), con el objetivo de ofrecer información y facilitar la inscripción en los Juegos de A.D. Cierzo.
    </p>
    <br/>

    <h3>
    1. Datos Identificativos
    </h3>
    <p>
    De conformidad con el artículo 10 de la Ley 34/2002 de Servicios de la Sociedad de la Información y Comercio Electrónico, se informa a los usuarios de los siguientes datos:
    </p>
    <ul>
      <li>Titular: Asociación Deportiva Cierzo proLGTB+</li>
      <li>Correo electrónico: juegoscierzo@cierzolgtb.org</li>
    </ul>
    <br/>

    <h3>
      2. Condiciones de Uso
    </h3>
    <p>
    El acceso y uso del Sitio Web están sujetos a las siguientes condiciones:
    </p>
    <ul>
      <li>Uso adecuado del Sitio Web: El usuario se compromete a utilizar este Sitio Web de conformidad con la ley, la moral, el orden público y las buenas costumbres. Asimismo, se obliga a no utilizar el Sitio Web para actividades ilícitas, ilegales o contrarias a los derechos de terceros.</li>
      <li>Exactitud de la información: A.D. Cierzo se esfuerza por garantizar que la información contenida en este Sitio Web sea correcta y esté actualizada, pero no puede garantizar la precisión, integridad o actualidad de la misma en todo momento.</li>
      <li>Modificación del contenido: A.D. Cierzo se reserva el derecho de modificar, actualizar o eliminar el contenido del Sitio Web sin previo aviso, así como de restringir o cancelar el acceso al mismo en cualquier momento.</li>
    </ul>
    <br/>

    <h3>
    3. Propiedad Intelectual e Industrial
    </h3>
    <p>
    Todos los contenidos del Sitio Web, entendidos como, a título enunciativo, textos, gráficos, logotipos, iconos, imágenes, así como el diseño gráfico y el código fuente, son propiedad de A.D. Cierzo proLGTB+ o de terceros que han autorizado su uso, y están protegidos por los correspondientes derechos de propiedad intelectual e industrial.
    </p>
    <ul>
      <li>Uso personal: Los usuarios del Sitio Web pueden visualizar, descargar y reproducir los contenidos para su uso personal, no comercial, siempre y cuando se respeten los derechos de propiedad intelectual y se haga mención a la titularidad de A.D. Cierzo.</li>
      <li>Prohibiciones: Queda prohibido reproducir, distribuir o modificar los contenidos del Sitio Web con fines comerciales sin la autorización expresa de A.D. Cierzo.</li>
    </ul>
    <br/>

    <h3>
    4. Exención de Responsabilidad
    </h3>
    <p>
    A.D. Cierzo no se hace responsable de los daños o perjuicios derivados del uso de este Sitio Web, tales como errores técnicos, fallos en el sistema, virus u otros elementos que puedan causar alteraciones en el sistema informático del usuario.
    </p>
    <ul>
      <li>Enlaces externos: El Sitio Web puede contener enlaces a sitios web de terceros. A.D. Cierzo no tiene control sobre esos sitios ni asume responsabilidad alguna por su contenido, servicios o prácticas de privacidad.</li>
    </ul>
    <br/>

    <h3>
    5. Protección de Datos
    </h3>
    <p>
    El tratamiento de los datos personales de los usuarios se regirá por lo dispuesto en la Política de Privacidad del Sitio Web. Al hacer uso del Sitio Web, los usuarios dan su consentimiento expreso para que sus datos personales sean tratados conforme a dicha política.
    </p>
    <br/>

    <h3>
    6. Legislación Aplicable y Jurisdicción
    </h3>
    <p>
    El presente Aviso Legal se rige por la normativa española vigente. Para la resolución de cualquier conflicto que pudiera derivarse del acceso o uso del Sitio Web, las partes se someten a la jurisdicción de los juzgados y tribunales de la ciudad de Zaragoza, renunciando expresamente a cualquier otro fuero que pudiera corresponderles.
    </p>
    <br/>

    <h3>
    7. Contacto
    </h3>
    <p>
    Para cualquier duda, consulta o sugerencia relacionada con el contenido de este Aviso Legal o el funcionamiento del Sitio Web, puedes ponerte en contacto con nosotros a través del correo electrónico juegoscierzo@cierzolgtb.org
    </p>
    <br/>



    </div>
  </Card>
  </div>


  </>);
}