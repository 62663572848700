import styles from './card.module.css';

export default function Card({ children, backgroundColor, id }) {
    const cssVariableColor = getComputedStyle(document.documentElement).getPropertyValue('--custom-bg-color');
    return (
        <div
            id={id}
            className={styles.card}
            style={{ backgroundColor: backgroundColor || cssVariableColor.trim() }} >
            {children}
        </div>
    );
}
