import styles from "./joincierzo.module.css";
import IconLabel from "../IconLabel/IconLabel";

export default function JoinCierzo({ joinCierzo }) {
  return (
    <>
      <div id={"joinCierzo"} className={styles.container}>
        <div className={styles.content}>
          <div className={styles.content__text}>
            <h1>{joinCierzo.title}</h1>
            <p>{joinCierzo.description}</p>
            <img id="joinCierzoPNG" loading="lazy" src={joinCierzo.imageSrc} alt="Become a member" />
          </div>
          <div className={styles.content__cards}>
            <div className={styles.content__member}>
              <IconLabel
                className={styles.iconLabel}
                text={joinCierzo.membership.buttonText}
                onClick={() => alert(joinCierzo.membership.buttonOnClickMessage)}
                buttonBackgroundColor={joinCierzo.membership.buttonBackgroundColor}
                backgroundColor={joinCierzo.membership.buttonBackground}
                textColor={joinCierzo.membership.buttonTextColor}
                icon={
                  <img loading="lazy"
                    src={joinCierzo.membership.buttonIconSrc}
                    alt={joinCierzo.membership.buttonIconAlt}
                  />
                }
              />
            </div>
            <div className={styles.content__footer}>
              <div className={styles.content__footer__text}>
                <h1>{joinCierzo.footer.membersCount}</h1>
                <h2 style={{ fontWeight: "light" }}>{joinCierzo.footer.membersLabel}</h2>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p>{joinCierzo.footer.membersText}</p>
              </div>
            </div>
          </div>
        </div>
        <img loading="lazy" className={styles.image} src={joinCierzo.footerImageSrc} alt="Become a member" />
      </div>
    </>
  );
}
