import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Button } from "react-bootstrap";
import GoogleButton from "react-google-button";
import { useUserAuth } from '../../../context/UserAuthContext';
import styles from './index.module.css'


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const { logIn, googleSignIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    try {
      await logIn(email, password);
      navigate("/admin");
    } catch (err) {
      // Personalizar el mensaje de error
      if (err.code) {
        setError("El correo y/o la contraseña han sido introducidos incorrectamente. Por favor, introdúzcalos de nuevo.");
      }
    }
  };

  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    try {
      await googleSignIn();
      navigate("/admin");
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <>
      <div className={styles.box}>
        <div className={styles.header}>
          <h2>Entrar como administrador</h2>
        </div>


        <Form onSubmit={handleSubmit}>

          <Form.Group className={styles.email} controlId="formBasicEmail">
            <Form.Control
              type="email"
              placeholder="Correo"
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className={styles.password} controlId="formBasicPassword">
            <Form.Control
              type="password"
              placeholder="Contraseña"
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <div className={styles.error}>
            {error && <Alert variant="danger"><span>{error}</span></Alert>}
          </div>
          <div className={styles.login}>
            <Button variant="primary" type="Submit">
              ENTRAR
            </Button>
          </div>
        </Form>
        {/** Botón de entrar con google
        <div>
          <GoogleButton
            className="g-btn"
            type="dark"
            onClick={handleGoogleSignIn}
          />
        </div>*/}
      </div>

          {/**  Para registrarse
      <div className="p-4 box mt-3 text-center">
        Don't have an account? <Link to="/signup">Sign up</Link>
      </div>
        */}
    </>
  );
};

export default Login;