import styles from "./juegosadcierzo.module.css";
import IconLabel from "../IconLabel/IconLabel";
import PointAndText from "../PointAndText/PointAndText";
import { useNavigate } from "react-router-dom";


export default function JuegosADCierzo({ juegosADCierzo }) {
  const { edition, title, description, moreInfoButton, lastEdition } =
    juegosADCierzo;

  const navigate = useNavigate();

  function onClickJuegosCierzo() {
    navigate("/juegos-del-cierzo");
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div id="juegosADCierzo" className={styles.parentContainer}>
        <div className={styles.container}>
          <div className={styles.column1}>
            <div className={styles.column1__header}>
              <PointAndText
                text={edition.text}
                textColor={edition.textColor}
                pointColor={edition.pointColor}
              />
            </div>
            <div className={styles.column1__text}>
              <h1>{title}</h1>
              <p>{description}</p>
            </div>
            <div className={styles.column1__information}>
              <IconLabel
                className={styles.iconLabel}
                text={moreInfoButton.text}
                onClick={() => onClickJuegosCierzo()}
                buttonBackgroundColor={moreInfoButton.buttonBackgroundColor}
                backgroundColor={moreInfoButton.backgroundColor}
                textColor={moreInfoButton.textColor}
                icon={
                  <img loading="lazy"
                    src={moreInfoButton.iconSrc}
                    alt={moreInfoButton.iconAlt}
                  />
                }
              />
            </div>
          </div>
          <div className={styles.column2}>
            <div className={styles.column2__title}>
              <h4>{lastEdition.title}</h4>
            </div>
            <div className={styles.column2__image}>
              <img loading="lazy" src={lastEdition.imageSrc} alt="Última edición" />
            </div>
            <div className={styles.column2__footer}>
              {lastEdition.footer.map((item, index) => (
                <div key={index} className={styles.column2__footer__element}>
                  <h2>{item.number}</h2>
                  <h3>{item.label}</h3>
                </div>
              ))}
            </div>
          </div>
          <div className={styles.mobile__button}>
            <IconLabel
              className={styles.iconLabel}
              text={moreInfoButton.text}
              onClick={() => onClickJuegosCierzo()}
              buttonBackgroundColor={moreInfoButton.buttonBackgroundColor}
              backgroundColor={moreInfoButton.backgroundColor}
              textColor={moreInfoButton.textColor}
              icon={
                <img loading="lazy"
                  src={moreInfoButton.iconSrc}
                  alt={moreInfoButton.iconAlt}
                />
              }
            />
          </div>
        </div>
        <div className={styles.separator}></div>
      </div>
    </>
  );
}
