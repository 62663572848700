import React, { useState, useEffect } from "react";
import { Country, State, City } from 'country-state-city';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from "./formularioinscripcion.module.css";
import Card from "../Card/Card";
import PointAndText from "../PointAndText/PointAndText";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import { db } from '../../firebase';
import { collection, addDoc } from 'firebase/firestore';
import 'react-toastify/dist/ReactToastify.css';

export default function FormularioInscripcion(){

  /* Esto es con 'react-cuntry... */

  // Lista estática de provincias de España
  const provincesOfSpain = [
    "Álava", "Albacete", "Alicante", "Almería", "Asturias", "Ávila", "Badajoz", "Barcelona",
    "Burgos", "Cáceres", "Cádiz", "Cantabria", "Castellón", "Ceuta", "Ciudad Real", "Córdoba",
    "Cuenca", "Girona", "Granada", "Guadalajara", "Guipúzcoa", "Huelva", "Huesca", "Illes Balears",
    "Jaén", "La Coruña", "La Rioja", "Las Palmas", "León", "Lleida", "Lugo", "Madrid", "Málaga",
    "Melilla", "Murcia", "Navarra", "Ourense", "Palencia", "Pontevedra", "Salamanca", "Segovia",
    "Sevilla", "Soria", "Tarragona", "Santa Cruz de Tenerife", "Teruel", "Toledo", "Valencia",
    "Valladolid", "Vizcaya", "Zamora", "Zaragoza"
  ];

  const [formData, setFormData] = useState({
    name: "",
    apellido: "",
    birthday: new Date('2001-01-01'),
    DNI: "",
    email: "",
    phone: "",
    gender: "",
    selectedCountry: "",
    selectedRegion: "",
    club: "",
    memberNumber: "",
    clubName: "",
    camiseta: "",
    dinner: null,
    diet: "",
    alergies: "",
    acceptPolicy: false,
  });

  const [errors, setErrors] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "", // Limpiar error al cambiar el valor
    }));
  };



      // En validateStep, ajusta la validación en el paso 3
      const validateStep = () => {
        const newErrors = {};

        if (currentStep === 1) {
          if (!formData.name) newErrors.name = "El nombre es obligatorio";
          if (formData.name && formData.name.length > 25) newErrors.name = "El nombre no puede exceder 25 caracteres";

          if (!formData.apellido) newErrors.apellido = "El apellido es obligatorio";
          if (formData.apellido && formData.apellido.length > 25) newErrors.apellido = "El apellido no puede exceder 25 caracteres";

          if (!formData.birthday) newErrors.birthday = "La fecha de nacimiento es obligatoria";
          if (!formData.DNI) newErrors.DNI = "El DNI es obligatorio";
          if (formData.DNI && formData.DNI.length > 25) newErrors.DNI = "El DNI no puede exceder 25 caracteres";

          if (!formData.email) {
            newErrors.email = "El correo electrónico es obligatorio";
          } else if (!isValidEmail(formData.email)) {
            newErrors.email = "Debes introducir un email válido"; // Mensaje específico
          }
          if (!formData.phone) newErrors.phone = "El teléfono es obligatorio";
          if (formData.phone && formData.phone.length > 25) newErrors.phone = "El teléfono no puede exceder 25 caracteres";
        } else if (currentStep === 2) {
          if (!formData.gender) newErrors.gender = "El género es obligatorio";
          if (!formData.selectedCountry) newErrors.selectedCountry = "El país es obligatorio";
          if (!formData.selectedRegion) newErrors.selectedRegion = "La región es obligatoria";
          if (!formData.club) newErrors.club = "El club es obligatorio";

          if (formData.club === "cierzo" && !formData.memberNumber) {
            newErrors.memberNumber = "El número de socio es obligatorio";
          }

          if (formData.club === "otherClub" && !formData.clubName) {
            newErrors.clubName = "El nombre del club es obligatorio";
          }
          if (!formData.camiseta) newErrors.camiseta = "La talla de camiseta es obligatoria";
        } else if (currentStep === 3) {
          if (!formData.dinner) newErrors.dinner = "Coméntanos si vienes o no a cenar";

          if (formData.dinner === "dinner") {
            if (!formData.diet) newErrors.diet = "El tipo de dieta es obligatorio";
          }
        } else if (currentStep === 4) {
          if (!formData.acceptPolicy) newErrors.acceptPolicy = "Debes aceptar la política de privacidad y protección de datos";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
      };

    const handleNext = () => {
      if (validateStep()) {
        setCompletedSteps((prev) => [...new Set([...prev, currentStep])]);
        setCurrentStep((prevStep) => prevStep + 1);
      }
    };

    const navigateToStep = (step) => {
      if (step <= currentStep || completedSteps.includes(step)) {
        setCurrentStep(step);
      } else {
        toast.warning("Completa los pasos anteriores antes de avanzar.", {
          position: "bottom-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false
        });
      }
    };

    const isBolaActive = (step) => {
      return step <= currentStep || completedSteps.includes(step);
    };

    const isLineaSolid = (step) => {
      return step < currentStep;
    };


    // Clave secreta para validar la escritura en Firestore
const SECRET_KEY = "CodeCierzoLGTB+123!"; // Asegúrate de cambiarlo a un valor seguro y único

const handleFirestoreSubmit = async () => {
  try {
    // Agregar la clave secreta a los datos del formulario
    const formDataWithSecret = {
      ...formData,
      secretKey: SECRET_KEY, // Agregar el campo secretKey
    };

    await addDoc(collection(db, 'inscriptions'), formDataWithSecret);
    toast.success("Formulario enviado exitosamente", {
      position: "bottom-center",
      autoClose: 3000,
    });
    // Aquí puedes redirigir o limpiar el formulario si deseas
  } catch (error) {
    toast.error("Error al enviar el formulario", {
      position: "bottom-center",
      autoClose: 3000,
    });
    console.error("Error adding document: ", error);
  }

  handleNext();
};

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};


  const navigate = useNavigate();
  function onClickPrivacidad() {
    navigate("/politica-de-privacidad");
    window.scrollTo(0, 0);
  }

  const conditionalLineBreak = window.innerWidth < 500 ? "\n" : " ";

  return (
  <div className={styles.superContainer}>
    {currentStep === 1 && (
    <Card backgroundColor="#FFF1FE">
        <div className={styles.container__contacto}>
        <div className={styles["text-section"]}>
            <div className={styles.pointAndText}>
              <PointAndText text="Formulario de inscripción" pointColor="#DCB8FF" />
            </div>

            <div className={styles.bolas__column}>
              <div className={styles.bola__container} onClick={() => navigateToStep(1)}>
                <div className={`${styles.bola} ${isBolaActive(1) ? styles.bola__active : ""}`}></div>
                <div className={`${styles.bola__text} ${isBolaActive(1) ? styles.bola__text__active : ""}`}>
                  DATOS DE CONTACTO
                </div>
              </div>
              <div className={`${styles.lineaContainer} ${isLineaSolid(1) ? styles.lineaContainer__solid : ""}`}/>
              <div className={styles.bola__container} onClick={() => navigateToStep(2)}>
                <div className={`${styles.bola} ${isBolaActive(2) ? styles.bola__active : ""}`}></div>
                <div className={`${styles.bola__text} ${isBolaActive(2) ? styles.bola__text__active : ""}`}>
                  DATOS DE COMPETICIÓN
                </div>
              </div>
              <div className={`${styles.lineaContainer} ${isLineaSolid(2) ? styles.lineaContainer__solid : ""}`}/>
              <div className={styles.bola__container} onClick={() => navigateToStep(3)}>
                <div className={`${styles.bola} ${isBolaActive(3) ? styles.bola__active : ""}`}></div>
                <div className={`${styles.bola__text} ${isBolaActive(3) ? styles.bola__text__active : ""}`}>
                  CENA
                </div>
              </div>
            </div>
          </div>

          {/* Right side - Form */}
          <div className={styles["form-section"]}>
          <h3>DATOS DE CONTACTO</h3>
          <form onSubmit={(e) => { e.preventDefault(); handleNext(); }}>
            <input
                type="text"
                name="name"
                placeholder="Nombre"
                className={styles.input}
                value={formData.name}
                onChange={handleChange}
                required
                maxLength="25"
              />
              <input
                type="text"
                name="apellido"
                placeholder="Apellidos"
                className={styles.input}
                value={formData.apellido}
                onChange={handleChange}
                required
                maxLength="25"
              />
              <div className={styles.row}>
              <DatePicker
                selected={formData.birthday}
                onChange={(date) => setFormData({ ...formData, birthday: date.toISOString().split('T')[0] })}
                dateFormat="dd/MM/yyyy"
                placeholderText="DD/MM/AAAA"
                maxDate={new Date()}
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100}
                className={styles.input}
                required
              />

                <div>
                  <input
                    type="text"
                    name="DNI"
                    placeholder="DNI/NIF"
                    className={styles.input}
                    value={formData.DNI}
                    onChange={handleChange}
                    required
                    maxLength="25"
                  />
                </div>
              </div>
              <div className={styles.row}>
                <div>
                  <input
                    type="email"
                    name="email"
                    placeholder="Correo electrónico"
                    className={styles.input}
                    value={formData.email}
                    onChange={handleChange}
                    required
                    maxLength="50"
                  />
                  {errors.email && <div className={styles.errorMessage}>{errors.email}</div>}
                </div>
                <div>
                  <input
                    type="text"
                    name="phone"
                    placeholder="Teléfono"
                    className={styles.input}
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    maxLength="25"
                  />
                </div>
              </div>
              <button type="submit" className={styles.button}>
                SIGUIENTE
              </button>
          </form>
          </div>
        </div>
        </Card>
        )}
        {currentStep === 2 && (
        <Card backgroundColor="#FFF1FE">
        <div className={styles.container__competicion}>
          <div className={styles["text-section"]}>
            <div className={styles.pointAndText}>
              <PointAndText text="Formulario de inscripción" pointColor="#DCB8FF" />
            </div>

            <div className={styles.bolas__column}>
              <div className={styles.bola__container} onClick={() => navigateToStep(1)}>
                <div className={`${styles.bola} ${isBolaActive(1) ? styles.bola__active : ""}`}></div>
                <div className={`${styles.bola__text} ${isBolaActive(1) ? styles.bola__text__active : ""}`}>
                  DATOS DE CONTACTO
                </div>
              </div>
              <div className={`${styles.lineaContainer} ${isLineaSolid(1) ? styles.lineaContainer__solid : ""}`}/>
              <div className={styles.bola__container} onClick={() => navigateToStep(2)}>
                <div className={`${styles.bola} ${isBolaActive(2) ? styles.bola__active : ""}`}></div>
                <div className={`${styles.bola__text} ${isBolaActive(2) ? styles.bola__text__active : ""}`}>
                  DATOS DE COMPETICIÓN
                </div>
              </div>
              <div className={`${styles.lineaContainer} ${isLineaSolid(2) ? styles.lineaContainer__solid : ""}`}/>
              <div className={styles.bola__container} onClick={() => navigateToStep(3)}>
                <div className={`${styles.bola} ${isBolaActive(3) ? styles.bola__active : ""}`}></div>
                <div className={`${styles.bola__text} ${isBolaActive(3) ? styles.bola__text__active : ""}`}>
                  CENA
                </div>
              </div>
            </div>
          </div>

          {/* Right side - Form */}
          <div className={styles["form-section"]}>
            <h3>DATOS DE COMPETICIÓN</h3>
            <form onSubmit={(e) => { e.preventDefault(); handleNext(); }}>
            <select
              name="gender"
              value={formData.gender}
              onChange={handleChange}
              className={styles.input}
              required
            >
              <option value="" disabled>Género</option>
              <option value="masc">Masculino</option>
              <option value="fem">Femenino</option>
              <option value="non-binary">No binario</option>
              <option value="na">Prefiero no contestar</option>
            </select>
              <div className={styles.row}>
                <select
                  name="selectedCountry"
                  value={formData.selectedCountry}
                  onChange={handleChange}
                  className={styles.input}
                  required
                >
                  <option value="">Selecciona tu país</option>
                  {Country.getAllCountries().map((country) => (
                    <option key={country.isoCode} value={country.isoCode}>
                      {country.name}
                    </option>
                  ))}
                </select>

                {/* Selector de Región/Provincia */}
                {formData.selectedCountry && (
                  <select
                    name="selectedRegion"
                    value={formData.selectedRegion}
                    onChange={handleChange}
                    className={styles.input}
                    required
                  >
                    <option value="">
                      Selecciona tu {formData.selectedCountry === 'ES' ? 'provincia' : 'región'}
                    </option>
                    {formData.selectedCountry === 'ES'
                      ? provincesOfSpain.map((province) => (
                          <option key={province} value={province}>
                            {province}
                          </option>
                        ))
                      : State.getStatesOfCountry(formData.selectedCountry).map((state) => (
                          <option key={state.isoCode} value={state.isoCode}>
                            {state.name}
                          </option>
                        ))}
                  </select>
                )}

              </div>
              <div className={styles.row}>
              <div>
                <select
                  name="club"
                  value={formData.club} // Cambié de formData.sport a formData.club
                  onChange={handleChange}
                  className={styles.input}
                  required
                >
                  <option value="" disabled>Club</option>
                  <option value="cierzo">Cierzo</option>
                  <option value="otherClub">Otro club</option>
                </select>
              </div>

              {formData.club === 'cierzo' && (
                <div>
                  <input
                    type="text"
                    name="memberNumber"
                    placeholder="Número de socio"
                    className={styles.input}
                    value={formData.memberNumber} // Cambié de formData.email a formData.memberNumber
                    onChange={handleChange}
                    required
                    maxLength="25"
                  />
                </div>
              )}

              {formData.club === 'otherClub' && (
                <div>
                  <input
                    type="text"
                    name="clubName"
                    placeholder="Nombre del club"
                    className={styles.input}
                    value={formData.clubName} // Cambié de formData.email a formData.clubName
                    onChange={handleChange}
                    required
                    maxLength="25"
                  />
                </div>
              )}
            </div>

              <select
                name="camiseta"
                value={formData.camiseta}
                onChange={handleChange}
                className={styles.input}
                required
              >
                <option value="" disabled>Talla de camiseta</option>
                <option value="XS">XS</option>
                <option value="S">S</option>
                <option value="M">M</option>
                <option value="L">L</option>
                <option value="XL">XL</option>
              </select>
              <div className={styles.letraPequena}>
                * El precio por participar son X € (Incluye camiseta y pack de bienvenida, la cena se paga a parte).
              </div>
              <button type="submit" className={styles.button}>
                SIGUIENTE
              </button>
          </form>
          </div>
        </div>
        </Card>
        )}
        {currentStep === 3 && (
        <Card backgroundColor="#FFF1FE">
        <div className={styles.container__cena}>
        <div className={styles["text-section"]}>
            <div className={styles.pointAndText}>
              <PointAndText text="Formulario de inscripción" pointColor="#DCB8FF" />
            </div>

            <div className={styles.bolas__column}>
              <div className={styles.bola__container} onClick={() => navigateToStep(1)}>
                <div className={`${styles.bola} ${isBolaActive(1) ? styles.bola__active : ""}`}></div>
                <div className={`${styles.bola__text} ${isBolaActive(1) ? styles.bola__text__active : ""}`}>
                  DATOS DE CONTACTO
                </div>
              </div>
              <div className={`${styles.lineaContainer} ${isLineaSolid(1) ? styles.lineaContainer__solid : ""}`}/>
              <div className={styles.bola__container} onClick={() => navigateToStep(2)}>
                <div className={`${styles.bola} ${isBolaActive(2) ? styles.bola__active : ""}`}></div>
                <div className={`${styles.bola__text} ${isBolaActive(2) ? styles.bola__text__active : ""}`}>
                  DATOS DE COMPETICIÓN
                </div>
              </div>
              <div className={`${styles.lineaContainer} ${isLineaSolid(2) ? styles.lineaContainer__solid : ""}`}/>
              <div className={styles.bola__container} onClick={() => navigateToStep(3)}>
                <div className={`${styles.bola} ${isBolaActive(3) ? styles.bola__active : ""}`}></div>
                <div className={`${styles.bola__text} ${isBolaActive(3) ? styles.bola__text__active : ""}`}>
                  CENA
                </div>
              </div>
            </div>
          </div>

          {/* Right side - Form */}
          <div className={styles["form-section"]}>
          <h3>CENA</h3>
          <p>Tras la competición, el club organiza una cena en Xlugar. ¿Te animas?</p>
            <form onSubmit={(e) => { e.preventDefault(); handleNext(); }}>
            <div className={styles.cena}>
                <div className={styles.cena__checkbox}>
                  <input
                    type="radio"
                    name="dinner"
                    value="dinner" // Cambia aquí
                    checked={formData.dinner === "dinner"}
                    onChange={(e) => handleChange(e)}
                    required
                    id="dinner-radio"
                  />
                  <label htmlFor="dinner-radio" className={styles.customCheckbox}>
                    <span className={styles.checkmark}></span> Quiero cenar
                  </label>
                </div>

                <div className={styles.noCena__checkbox}>
                  <input
                    type="radio"
                    name="dinner"
                    value="noDinner" // Cambia aquí
                    checked={formData.dinner === "noDinner"}
                    onChange={(e) => handleChange(e)}
                    required
                    id="noDinner-radio"
                  />
                  <label htmlFor="noDinner-radio" className={styles.customCheckbox}>
                    <span className={styles.checkmark}></span> No quiero cenar
                  </label>
                </div>
                {errors.dinner && (
                  <div className={styles.errorMessage}>{errors.dinner}</div>
                )}
              </div>
              <div>
                <select
                  name="diet"
                  value={formData.diet}
                  onChange={handleChange}
                  className={styles.input}
                  required={formData.dinner === "dinner"}
                  disabled={formData.dinner !== "dinner"} // Desactiva si no se selecciona "Quiero cenar"
                >
                  <option value="" disabled>Tipo de dieta</option>
                  <option value="Omnívoro">Omnívoro</option>
                  <option value="Vegetariana">Vegetariana</option>
                  <option value="Vegana">Vegana</option>
                  <option value="Halal">Halal</option>
                </select>
              </div>

              <textarea
                name="alergies"
                placeholder="Comenta aquí si tienes algún tipo de alergia (Opcional)"
                className={styles.textarea}
                value={formData.alergies}
                onChange={handleChange}
                rows="4"
                required={false}
                disabled={formData.dinner !== "dinner"} // Desactiva si no se selecciona "Quiero cenar"
                maxLength="50"
              />

              <div className={styles.letraPequena}>
                * El precio de la cena se añade al precio de la competición y son 20 €.
              </div>
              <button type="button" className={styles.button__finalizar} onClick={handleFirestoreSubmit}>
                FINALIZAR
              </button>
          </form>
          </div>
        </div>
        </Card>
        )}
        {currentStep === 4 && (
        <Card backgroundColor="#FFF1FE">
        <div className={styles.container__pagar}>
        <div className={styles["text-section"]}>
            <div className={styles.pointAndText}>
              <PointAndText text="Formulario de inscripción" pointColor="#DCB8FF" />
            </div>

            <div className={styles.bolas__column}>
              <div className={styles.bola__container} onClick={() => navigateToStep(1)}>
                <div className={`${styles.bola} ${isBolaActive(1) ? styles.bola__active : ""}`}></div>
                <div className={`${styles.bola__text} ${isBolaActive(1) ? styles.bola__text__active : ""}`}>
                  DATOS DE CONTACTO
                </div>
              </div>
              <div className={`${styles.lineaContainer} ${isLineaSolid(1) ? styles.lineaContainer__solid : ""}`}/>
              <div className={styles.bola__container} onClick={() => navigateToStep(2)}>
                <div className={`${styles.bola} ${isBolaActive(2) ? styles.bola__active : ""}`}></div>
                <div className={`${styles.bola__text} ${isBolaActive(2) ? styles.bola__text__active : ""}`}>
                  DATOS DE COMPETICIÓN
                </div>
              </div>
              <div className={`${styles.lineaContainer} ${isLineaSolid(2) ? styles.lineaContainer__solid : ""}`}/>
              <div className={styles.bola__container} onClick={() => navigateToStep(3)}>
                <div className={`${styles.bola} ${isBolaActive(3) ? styles.bola__active : ""}`}></div>
                <div className={`${styles.bola__text} ${isBolaActive(3) ? styles.bola__text__active : ""}`}>
                  CENA
                </div>
              </div>
            </div>
          </div>

          {/* Right side - Form */}
          <div className={styles.desglose}>
          <form onSubmit={(e) => { e.preventDefault(); handleNext(); }}>
          <div>
            <h3 style={{marginBottom:'16px'}}>DESGLOSE</h3>

            <div>
              <div className={styles.desgloseTorneo}>
                <div  className={styles.desgloseContent}>
                  <p className={styles.desgloseTitle}>
                    INSCRIPCIÓN AL TORNEO + CAMISETA
                  </p>
                  <p className={styles.desgloseDescription}>
                    {`( Camiseta talla ${formData.camiseta} )`}
                  </p>
                </div>
                <div className={styles.precio}>
                  <p>15 €</p>
                </div>
              </div>

              {formData.dinner === "dinner" && (
                <div className={styles.desgloseCena}>
                  <div className={styles.desgloseContent}>
                    <p className={styles.desgloseTitle}>CENA</p>
                    <p className={styles.desgloseDescription}>
                    {`( ${formData.diet}, ${conditionalLineBreak} ${formData.alergies ? "Específica alergias" : "Sin alergias"} )`}
                    </p>
                  </div>
                  <div className={styles.precio}>
                    <p>20 €</p>
                  </div>
                </div>
              )}
              <div className={styles.total}>
                <p>TOTAL</p> <p className={styles.totalTotal}>{`${formData.dinner === "dinner" ? "35 €" : "15 €"}`} </p>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles["checkbox-container"]}>
              <input
                type="checkbox"
                name="acceptPolicy"
                className={styles.checkbox}
                checked={formData.acceptPolicy}
                onChange={handleChange}
                required
              />
              <span className={styles.spanSpan}>
                <a className={styles.notLink}>He leído y acepto la </a>
                <a className={styles.link} onClick={() => onClickPrivacidad()}>
                  política de privacidad y protección de datos
                </a>
                .{" "}
              </span>
            </div>
            <button type="submit" className={styles.button__finalizar}>
              PAGAR
            </button>
          </div>
          </form>
          </div>
        </div>
        </Card>
        )}
      </div>

  );
};

