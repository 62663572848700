import styles from "./header.module.scss";

export default function HeaderItem({ text, icon, onClick }) {
  return (
    <div className={styles.header__item} onClick={(e) => onClick(e)}>
      {text}
      {icon ? (
        <img loading="lazy" src={`assets/icons/${icon}.svg`} alt={`${icon} logo`} />
      ) : (
        ""
      )}
    </div>
  );
}
