import React, { useState, useEffect } from "react";
import styles from "./contactForm.module.css";
import IconLabel from "../IconLabel/IconLabel";
import Card from "../Card/Card";
import PointAndText from "../PointAndText/PointAndText";
import emailjs from "emailjs-com";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AnimatedButton } from "../AnimatedButton/AnimatedButton";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    sport: "",
    acceptPolicy: false,
    description: "",
  });
  const [canSend, setCanSend] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false); // Cambia según el límite
  const LIMIT = 1;

  const navigate = useNavigate();

  useEffect(() => {
    checkSendLimit();
  }, []);

  const checkSendLimit = () => {
    const savedData = JSON.parse(localStorage.getItem('sendLimit')) || {};
    const today = new Date().toISOString().split('T')[0];

    if (savedData.date === today && savedData.count >= LIMIT) {
      setCanSend(false);
      setIsSubscribed(true); // Se establece en "Enviado" si se ha alcanzado el límite
    } else {
      setCanSend(true);
      setIsSubscribed(false); // Permite el envío si el límite no se ha alcanzado
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.acceptPolicy) {
      alert("Debes aceptar la política de privacidad.");
      return;
    }

    if (!canSend) {
      toast.error("Has alcanzado el límite de envíos permitidos para hoy.", {
        position: "bottom-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
        transition: Bounce,
      });
      return;
    }

    emailjs
      .send(
        "service_z3rceds",
        "template_8oempyq",
        {
          fullName: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.description,
          sport: formData.sport,
        },
        "09DZIimfah5aCx3c2"
      )
      .then(
        (response) => {
          toast.success("Tu mensaje ha sido enviado con éxito.", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });

          // Resetea el formulario
          setFormData({
            name: "",
            phone: "",
            email: "",
            sport: "",
            acceptPolicy: false,
            description: "",
          });

          // Actualiza el contador en localStorage
          const savedData = JSON.parse(localStorage.getItem('sendLimit')) || {};
          const today = new Date().toISOString().split('T')[0];

          if (savedData.date === today) {
            savedData.count += 1;
          } else {
            savedData.date = today;
            savedData.count = 1;
          }

          localStorage.setItem('sendLimit', JSON.stringify(savedData));
          checkSendLimit();
        },
        (error) => {
          toast.error("Hubo un error al enviar el mensaje. Inténtalo de nuevo más tarde.", {
            position: "bottom-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
            transition: Bounce,
          });
        }
      );
  };

  const onClickPrivacidad = () => {
    navigate("/politica-de-privacidad");
    window.scrollTo(0, 0);
  };

  return (
    <Card backgroundColor="var(--color-light)">
      <div className={styles.container}>
        <div className={styles["text-section"]}>
          <PointAndText text="Infórmate" pointColor="var(--color-warning)" />
          <h2 className={styles.title}>JUNTOS CRECEMOS</h2>
          <p className={styles.description}>
            Estamos encantados de resolver tus dudas y ayudarte a encontrar la
            actividad perfecta para ti. ¡Te esperamos!
          </p>
          <div className={styles.iconLabel}>
            <IconLabel
              text="info@cierzo.com"
              onClick={() => alert("Button clicked")}
              buttonBackgroundColor="white"
              backgroundColor="var(--color-warning)"
              textColor="var(--primary-color)"
              icon={<img loading="lazy" src="/assets/icons/email.svg" alt="Email Icon" />}
            />
          </div>
        </div>

        {/* Right side - Form */}
        <div className={styles["form-section"]}>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="name"
              placeholder="Nombre"
              className={styles.input}
              value={formData.name}
              onChange={handleChange}
              required
            />
            <div className={styles.row}>
              <div>
                <input
                  type="tel"
                  name="phone"
                  placeholder="Nº de teléfono"
                  className={styles.input}
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  className={styles.input}
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <input
              type="text"
              name="sport"
              placeholder="¿Qué deporte te interesa?"
              className={styles.input}
              value={formData.sport}
              onChange={handleChange}
              required
            />
            <textarea
              name="description"
              placeholder="¿Tienes algo que comentarnos?"
              className={styles.textarea}
              value={formData.description}
              onChange={handleChange}
              rows="4"
              required
            />
            <div className={styles["checkbox-container"]}>
              <input
                type="checkbox"
                name="acceptPolicy"
                className={styles.checkbox}
                checked={formData.acceptPolicy}
                onChange={handleChange}
                required
              />
              <span className={styles.spanSpan}>
                <a className={styles.notLink}>He leído y acepto la </a>
                <a className={styles.link} onClick={onClickPrivacidad}>
                  política de privacidad
                </a>.
              </span>
            </div>
            <AnimatedButton
              buttonColor="var(--color-secondary)"
              subscribeStatus={isSubscribed} // el botón muestra el estado según isSubscribed
              buttonTextColor="white"
              initialText="Envía"
              changeText="Enviado"
              type="submit" // botón de envío
              disabled={!canSend} // se desactiva si canSend es falso
            />
            <ToastContainer
              position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              transition={Bounce}
            />
          </form>
        </div>
      </div>
    </Card>
  );
};

export default ContactForm;
