import { useEffect, useState } from "react";
import IconLabel from "../IconLabel/IconLabel";
import styles from "./header.module.scss";
import HeaderItem from "./HeaderItem";
import PointAndText from "../PointAndText/PointAndText";
import { useNavigate } from "react-router-dom";
import AdminPopUp from "../AdminPopUp/AdminPopUp";
import Card from '../Card/Card';
import Login from '../LogIn/LogIn/LogIn';

export default function Header({onContactClick}) {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1000);
  const [showSportsMenu, setShowSportsMenu] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showAdminPopUp, setShowAdminPopUp] = useState(false);

  const navigate = useNavigate();

  function checkScrolled() {
    if (window.scrollY > 0) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  }

  useEffect(() => {
    checkScrolled();
    window.addEventListener("scroll", function () {
      checkScrolled();
    });

    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function onClickCompanyLogo() {
    navigate("/");
    window.scrollTo(0, 0);
  }

  function onClickAboutUs() {
    navigate("/sobre-nosotros");
    window.scrollTo(0, 0);
  }

  function onClickJuegosCierzo() {
    navigate("/juegos-del-cierzo");
    window.scrollTo(0, 0);
  }

  function onClickMedia() {
    navigate("/galeria");
    window.scrollTo(0, 0);
  }

  function onClickHome() {
    navigate("/");
    window.scrollTo(0, 0);
  }

  function handleSportClick(sportName) {
    console.log(`Navigating to details of ${sportName}`);
    navigate(`/${sportName.toLowerCase()}`);
    window.scrollTo(0, 0);
  }

  function onClickLogin() {
    setShowAdminPopUp(true);
  }

  function closeLogin() {
    setShowAdminPopUp(false);
  }

  function expandHeader() {
    setIsExpanded(true);
  }
  function collapseHeader() {
    setIsExpanded(false);
  }

  function handleSportsMenu() {
    setShowSportsMenu((prev) => !prev);
  }

  function onContactClickAndClose() {
    onContactClick();
    setTimeout(() => {
      collapseHeader();
    }, 500);
  }

  /*ADMIN POPUP*/
  function AdminPopUpComponent({ show, onClose }) {
    if (!show) return null;
    const closeIconSrc = window.innerWidth > 1000 ? "assets/icons/cross.svg" : "assets/icons/cross__white.svg";

    return (
      <div className={styles.adminOverlay}>
        <div className={styles.adminContainer}>
          <div onClick={onClose} className={styles.closeButton} >
            <img src={closeIconSrc}/>
          </div>
          <Card backgroundColor="var(--color-light)">
            <Login />
          </Card>
        </div>
      </div>
    );
  }

  return (
    <>
      {!isSmallScreen ? (
        <header
          className={
            open
              ? `${styles.header} ${styles.open}`
              : scrolled
              ? `${styles.header} ${styles.scrolled}`
              : `${styles.header}`
          }
        >
          <>
            <div className={styles.static}>
              <div className={styles.main}>
                {!scrolled && !open ? <div /> : ""}
                <img
                  loading="lazy"
                  onClick={onClickCompanyLogo}
                  className={styles.company__header}
                  src={`assets/icons/LogoCierzoArcoiris.svg`}
                  alt={`company__header logo`}
                />
                <div className={styles.absolute}>
                  <HeaderItem
                    text="Sobre nosotros"
                    onClick={() => onClickAboutUs()}
                  />
                  <HeaderItem
                    text="Juegos del cierzo"
                    onClick={() => onClickJuegosCierzo()}
                  />
                  <HeaderItem text="Deportes" onClick={() => setOpen(!open)} />
                  <HeaderItem text="Galería" onClick={() => onClickMedia()} />
                  <HeaderItem icon="user" onClick={onClickLogin} />
                </div>
              </div>
              <IconLabel
                text="INFÓRMATE"
                backgroundColor="white"
                buttonBackgroundColor="#1E1445"
                textColor="white"
                icon={
                  <img
                    loading="lazy"
                    src={`assets/icons/arrow__down__blue.svg`}
                    alt={`arrow__down__blue logo`}
                  />
                }
              />
            </div>
            <div className={styles.content}>
              <div className={styles.header__title}>
                <PointAndText text="Deportes" pointColor="var(--color-accent)" />
                <h1>ENCUENTRA TU EQUIPO</h1>
              </div>
              <div className={styles.sports}>
                <div
                  className={styles.container}
                  onClick={() => handleSportClick("Voley")}
                >
                  <div className={styles.text}>Voley</div>
                  <div className={`${styles.sport} ${styles.volley}`} />
                </div>
                <div
                  className={styles.container}
                  onClick={() => handleSportClick("Baloncesto")}
                >
                  <div className={styles.text}>Baloncesto</div>
                  <div className={`${styles.sport} ${styles.raquet}`} />
                </div>
                <div
                  className={styles.container}
                  onClick={() => handleSportClick("futbol")}
                >
                  <div className={styles.text}>Fútbol</div>
                  <div className={`${styles.sport} ${styles.basket}`} />
                </div>
                <div
                  className={styles.container}
                  onClick={() => handleSportClick("deportes-de-raqueta")}
                >
                  <div className={styles.text}>Deportes de raqueta</div>
                  <div className={`${styles.sport} ${styles.football}`} />
                </div>
                <div
                  className={styles.container}
                  onClick={() => handleSportClick("tiempo-libre")}
                >
                  <div className={styles.text}>Tiempo Libre</div>
                  <div className={`${styles.sport} ${styles.freeTime}`} />
                </div>
              </div>
            </div>
          </>
        </header>
      ) : (
        <header
          className={
            isExpanded
              ? `${styles.header} ${styles.isExpanded}`
              : `${styles.header}`
          }
        >
          {isExpanded ? (
            <div className={styles.container}>
              <div className={styles.head}>
                <img
                  loading="lazy"
                  onClick={() => onClickHome()}
                  className={styles.company}
                  src={"assets/icons/LogoCierzoArcoirisWhite.svg"}
                  alt={"company__white logo"}
                />
                <img
                  loading="lazy"
                  className={styles.icon}
                  onClick={() => collapseHeader()}
                  src={`assets/icons/cross__white.svg`}
                  alt={`cross__white logo`}
                />
              </div>
              <div className={styles.body}>
                <div
                  className={styles.menu__item}
                  onClick={() => onClickAboutUs()}
                >
                  SOBRE NOSOTROS
                </div>
                <div
                  className={styles.menu__item}
                  onClick={() => onClickJuegosCierzo()}
                >
                  JUEGOS DEL CIERZO
                </div>
                <div
                  className={styles.menu__item}
                  onClick={() => handleSportsMenu()}
                >
                  DEPORTES
                  <div
                    className={`${styles.mobile__sport__menu} ${
                      showSportsMenu ? styles.open : ""
                    }`}
                  >
                    <div
                      className={styles.mobile__sport}
                      onClick={() => handleSportClick("voley")}
                    >
                      <p>Vóley</p>
                    </div>
                    <div
                      className={styles.mobile__sport}
                      onClick={() => handleSportClick("baloncesto")}
                    >
                      <p>Baloncesto</p>
                    </div>
                    <div
                      className={styles.mobile__sport}
                      onClick={() => handleSportClick("Futbol")}
                    >
                      <p>Fútbol</p>
                    </div>
                    <div
                      className={styles.mobile__sport}
                      onClick={() => handleSportClick("deportes-de-raqueta")}
                    >
                      <p>Deporte de raqueta</p>
                    </div>
                    <div
                      className={styles.mobile__sport}
                      onClick={() => handleSportClick("tiempo-libre")}
                    >
                      <p>Tiempo libre</p>
                    </div>
                  </div>
                </div>
                <div
                  className={styles.menu__item}
                  onClick={() => onClickMedia()}
                >
                  GALERÍA
                </div>
                <div
                  className={styles.menu__item}
                  onClick={onContactClickAndClose}
                >
                  CONTACTO
                </div>
              </div>
              <div className={styles.footer}>
                <img
                  loading="lazy"
                  className={styles.icon}
                  onClick={onClickLogin}
                  src={`assets/icons/user__white.svg`}
                  alt={`user__white logo`}
                />
              </div>
            </div>
          ) : (
            <img
              loading="lazy"
              className={styles.burger}
              onClick={() => expandHeader()}
              src={`assets/icons/header__breadcrumb.svg`}
              alt={`header__breadcrumb logo`}
            />
          )}
        </header>
      )}
      <AdminPopUpComponent show={showAdminPopUp} onClose={closeLogin} />
    </>
  );
}
