import { useRef } from "react";
import Banner from "../components/Banner/Banner";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Backgrounds from "../components/Backgrounds/Backgrounds";
import ContactForm from "../components/ContactForm/ContactForm";
import JuegosDelCierzo from "../components/JuegosDelCierzo/JuegosDelCierzo";
import Logo from "../components/Logo/Logo";
import FormularioInscripcion from "../components/FormularioInscripcion/FormularioInscripcion";

export default function JuegosDelCierzoPage({ data }) {
  const contactFormRef = useRef(null);

  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <main>
      <Backgrounds imageUrl={"/assets/webImages/JuegosdelCierzoBG.webp"} />
      <Logo white={true} />
      <Banner data={data.banner} />
      <Header onContactClick={scrollToContactForm} />
      <JuegosDelCierzo juegosdelcierzopage={data.juegosdelcierzopage} />
      <br />
      <br />
      <br />
      <br />
      <div style={{position:'relative'}}>
        <FormularioInscripcion/>
      </div>
      <div ref={contactFormRef}>
        <ContactForm />
      </div>
      <Footer footer={data.footer} />
    </main>
  );
}
