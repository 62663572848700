import styles from "./prensa.module.css";
import Card from "../Card/Card";
import Logo from "../Logo/Logo";

export default function Prensa() {
  return (
    <>
      <div className={styles.container}>
        <Logo />
        <h1 className={styles.text}>PRENSA</h1>
        <Card>
          <div className={styles.content}>
            <p>
              Si quieres ponerte en contacto con el departamento de prensa de la
              AD Cierzo proLGTB+ te atenderemos en el teléfono{" "}
              <a href="tel:+34669757775">669757775</a>, cuyo responsable es
              David Lechón Blasco o bien por mail en{" "}
              <a href="mailto:info@cierzolgtb.org">info@cierzolgtb.org</a>
            </p>
          </div>
        </Card>
      </div>
    </>
  );
}
