import styles from "./politicaprivacidad.module.css";
import Card from "../Card/Card";

export default function PoliticaPrivacidad() {
  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.text}>POLÍTICA DE PRIVACIDAD</h1>

        <Card>
          <div className={styles.content}>
            <p>
      En A.D. Cierzo proLGTB+ estamos comprometidos con la protección de la privacidad de nuestros usuarios y participantes. A continuación, detallamos cómo recopilamos, usamos, almacenamos y protegemos tus datos personales cuando participas en los Juegos de A.D. Cierzo o utilizas nuestros servicios.
      </p>
      <br/>

      <h3>
      1. Responsable del Tratamiento de Datos
      </h3>

      <p>
      Asociación Deportiva Cierzo proLGTB+
      </p>

      <p>
      Correo electrónico: <a>juegoscierzo@cierzolgtb.org</a>
      </p>
      <br/>

      <h3>
      2. Datos que Recopilamos
      </h3>

      <p>
      Recopilamos la siguiente información personal con el propósito de gestionar tu participación en los Juegos de A.D. Cierzo:
      </p>
      <ul>
        <li>Nombre y apellidos</li>
        <li>Dirección de correo electrónico</li>
        <li>Número de teléfono</li>
        <li>Información sobre la modalidad deportiva en la que deseas participar</li>
        <li>Información sobre tu experiencia o nivel deportivo</li>
        <li>Datos de inscripción y pago, si aplica</li>
      </ul>
      <br/>

      <h3>
      3. Finalidad del Tratamiento de Datos
      </h3>
      <p>Utilizamos los datos que nos proporcionas para:</p>
      <ul>
        <li>Gestionar y organizar tu participación en los Juegos de A.D. Cierzo.</li>
        <li>Contactarte en relación con la inscripción, cambios en el evento o actualizaciones relevantes.</li>
        <li>Gestionar las actividades adicionales, como cenas, visitas o fiestas organizadas durante el evento.</li>
        <li>Garantizar el cumplimiento de nuestras normativas y proteger la seguridad de los participantes.</li>
      </ul>
      <br/>

      <h3>
      4. Base Legal para el Tratamiento de Datos
      </h3>
      <p>
      El tratamiento de tus datos personales se basa en tu consentimiento expreso, el cual otorgas al inscribirte en los Juegos de A.D. Cierzo. También podemos utilizar tus datos para cumplir con obligaciones legales relacionadas con la organización del evento.
      </p>
      <br/>

      <h3>
      5. Conservación de los Datos
      </h3>
      <p>
      Tus datos personales se conservarán mientras sean necesarios para la organización y desarrollo del evento. Tras la finalización del mismo, solo mantendremos los datos estrictamente necesarios durante el tiempo requerido por las leyes aplicables en materia de archivos y registros.
      </p>
      <br/>

      <h3>
      6. Derechos de los Interesados
      </h3>
      <p>
      Como titular de los datos personales, tienes derecho a:
      <ul>
        <li>Acceder a tus datos personales.</li>
        <li>Rectificar los datos inexactos o incompletos.</li>
        <li>Solicitar la supresión de tus datos cuando ya no sean necesarios para los fines para los que fueron recopilados.</li>
        <li>Oponerte al tratamiento de tus datos.</li>
        <li>Solicitar la limitación del tratamiento de tus datos en ciertos casos.</li>
        <li>Retirar tu consentimiento en cualquier momento.</li>
      </ul>
      <p>Para ejercer cualquiera de estos derechos, puedes enviarnos un correo electrónico a juegoscierzo@cierzolgtb.org</p>
      </p>
      <br/>

      <h3>
      7. Seguridad de los Datos
      </h3>
      <p>
      Implementamos las medidas de seguridad necesarias para garantizar la confidencialidad, integridad y disponibilidad de tus datos personales, protegiéndolos contra accesos no autorizados, alteraciones, divulgación o destrucción.
      </p>
      <br/>

      <h3>
      8. Modificaciones de la Política de Privacidad
      </h3>
      <p>
      Nos reservamos el derecho de actualizar o modificar esta Política de Privacidad en cualquier momento. Cualquier cambio será comunicado a través de esta página o mediante otros medios apropiados.
      </p>
      <br/>

      <h3>
      9. Contacto
      </h3>
      <p>
      Si tienes alguna duda o consulta sobre nuestra Política de Privacidad o sobre el tratamiento de tus datos personales, puedes contactarnos en: <a>juegoscierzo@cierzolgtb.org</a>
      </p>
          </div>
        </Card>
      </div>
    </>
  );
}
