import styles from "./juegosDelCierzo.module.css";
import Card from "../Card/Card";
import PointAndText from "../PointAndText/PointAndText";
import IconLabel from "../IconLabel/IconLabel";

export default function JuegosDelCierzo({ juegosdelcierzopage }) {
  return (
    <>
      <div className={styles.home}>
        <div className={styles.home__main}>
          <div className={styles.home__text}>
            <h1>JUEGOS DEL CIERZO</h1>
          </div>
          <Card backgroundColor="rgba(255, 255, 255, 0.59)">
            <div className={styles.footer}>
              <div className={styles.title}>
                XVIII JUEGOS DEL CIERZO | 2-4 DE MAYO DE 2025
              </div>
              <div className={styles.body}>
                Un evento deportivo inclusivo para todas las personas, sin
                importal el nivel o experiencia.
              </div>
              <IconLabel
                text="INSCRÍBETE AHORA"
                backgroundColor="var(--color-accent)"
                buttonBackgroundColor="white"
                textColor="var(--color-primary)"
                icon={
                  <img loading="lazy" src="assets/icons/top__right__arrow.svg" alt="arrow" />
                }
              />
            </div>
          </Card>
        </div>
      </div>
      <div className={styles.whatDoWeDo}>
        <br />
        <Card>
          <div className={styles.card__container}>
            <div className={styles.card__column1}>
              <div className={styles.column1__content}>
                <PointAndText
                  text="Los juegos"
                  pointColor="var(--color-secondary)"
                />
                <h1>{juegosdelcierzopage.description.title}</h1>
                <p>{juegosdelcierzopage.description.text}</p>
                <h2>{juegosdelcierzopage.sportsSection.title}</h2>
                <ul>
                  {juegosdelcierzopage.sportsSection.sportList &&
                  juegosdelcierzopage.sportsSection.sportList.length > 0 ? (
                    juegosdelcierzopage.sportsSection.sportList.map(
                      (sport, index) => (
                        <div key={index}>
                          <div className={styles.list__separator}></div>
                          <li>{sport}</li>
                        </div>
                      )
                    )
                  ) : (
                    <p>No hay deportes convocados.</p>
                  )}
                </ul>
              </div>
            </div>
            <div className={styles.card__column2}>
              <iframe
              src="https://www.youtube.com/embed/sw1M5VSt4oA?autoplay=1&loop=1&mute=1&playlist=sw1M5VSt4oA"
              title="YouTube video"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
              style={{width: '100%', height: '100%', objectFit: 'cover'}}/>
            </div>
          </div>
        </Card>
        <br />
      </div>

      <div className={styles.whenPage}>
        <div className={styles.whenPage__container}>
          <PointAndText
            text={juegosdelcierzopage.eventDetails.edition}
            textColor="white"
            pointColor="var(--color-accent)"
          />
          <h1>{juegosdelcierzopage.eventDetails.date}</h1>
          <p>{juegosdelcierzopage.eventDetails.registration}</p>
          <IconLabel
            className={styles.iconLabel}
            text={juegosdelcierzopage.ctaButton.text}
            onClick={() => alert(juegosdelcierzopage.ctaButton.onClickMessage)}
            buttonBackgroundColor={
              juegosdelcierzopage.ctaButton.buttonBackgroundColor
            }
            backgroundColor={juegosdelcierzopage.ctaButton.backgroundColor}
            textColor={juegosdelcierzopage.ctaButton.textColor}
            icon={
              <img loading="lazy"
                src={juegosdelcierzopage.ctaButton.iconSrc}
                alt={juegosdelcierzopage.ctaButton.iconAlt}
              />
            }
          />
        </div>
      </div>

      <div className={styles.subscribe}>
        <Card>
          <div className={styles.subscribe__header}>
            <h1>{juegosdelcierzopage.subscribe.title}</h1>
            <p>{juegosdelcierzopage.subscribe.description}</p>
          </div>
          <div className={styles.subscribe__content}>
            <div className={styles.subscribe__content__column1}>
              <PointAndText
                text="Inscripciones"
                pointColor="var(--color-secondary)"
              />
              <h1>{juegosdelcierzopage.subscribe.secondTitle}</h1>
              <p>
                {juegosdelcierzopage.subscribe.secondDescription
                  .split("/n")
                  .map((line, index) => (
                    <span key={index}>
                      {line.trim()}
                      <br />
                    </span>
                  ))}
              </p>
              <div className={styles.subscribe__content__column1__buttons}>
                {juegosdelcierzopage.subscribe.ctaButtons.map(
                  (button, index) => (
                    <IconLabel
                      key={index}
                      text={button.text}
                      onClick={() => alert(button.onClickMessage)}
                      buttonBackgroundColor={button.buttonBackgroundColor}
                      backgroundColor={button.backgroundColor}
                      textColor={button.textColor}
                      icon={<img loading="lazy" src={button.iconSrc} alt={button.iconAlt} />}
                    />
                  )
                )}
              </div>
            </div>
            <div className={styles.subscribe__content__column2}>
              <img loading="lazy"
                src={juegosdelcierzopage.subscribe.imageSrc}
                alt="Cierzo Pilar"
              />
            </div>
          </div>
        </Card>
      </div>

      {/* ESTO ES LA PARTE DE SPONSPORS, PONER CUANDO TENGAMOS ALGO
      <div className={styles.sponsors}>
        <div className={styles.sponsors__grid1}>
          {juegosdelcierzopage.sponsors.institutionalSponsors &&
          juegosdelcierzopage.sponsors.institutionalSponsors.length > 0 ? (
            juegosdelcierzopage.sponsors.institutionalSponsors.map(
              (sponsor, index) => (
                <div key={index} className={styles.sponsor__item}>
                  <img loading="lazy" src={sponsor} alt={`Institutional Sponsor ${index}`} />
                </div>
              )
            )
          ) : (
            <p>No hay patrocinadores institucionales.</p>
          )}
        </div>
        <div className={styles.sponsors__separator}></div>
        <div className={styles.sponsors__grid2}>
          {juegosdelcierzopage.sponsors.commercialSponsors &&
          juegosdelcierzopage.sponsors.commercialSponsors.length > 0 ? (
            juegosdelcierzopage.sponsors.commercialSponsors.map(
              (sponsor, index) => (
                <div key={index} className={styles.sponsor__item}>
                  <img loading="lazy" src={sponsor} alt={`Commercial Sponsor ${index}`} />
                </div>
              )
            )
          ) : (
            <p>No hay patrocinadores comerciales.</p>
          )}
        </div>
      </div>
      */}
    </>
  );
}
