import { useState } from "react";
import Accordion from "./Accordion";

export default function AccordionList({ props }) {
  const [openAccordion, setOpenAccordion] = useState(null);

  const handleToggle = (index) => {
    setOpenAccordion(index === openAccordion ? null : index);
  };

  return (
    <div>
      {props.map((accordion, index) => (
        <Accordion
          key={index}
          isOpen={openAccordion === index}
          onToggle={() => handleToggle(index)}
          props={accordion}
        />
      ))}
    </div>
  );
}
