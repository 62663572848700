import PoliticaPrivacidad from "../components/PoliticaPrivacidad/PoliticaPrivacidad";
import Banner from "../components/Banner/Banner";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Backgrounds from "../components/Backgrounds/Backgrounds";
import ContactForm from "../components/ContactForm/ContactForm";
import { useRef } from "react";


export default function AvisoLegalPage({ data }) {

  const contactFormRef = useRef(null);

  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <main>
      <Backgrounds imageUrl={"/assets/webImages/JuegosDelCierzoInfoBG.webp"}></Backgrounds>
      <Banner data={data.banner} />
      <Header onContactClick={scrollToContactForm}  />
      <PoliticaPrivacidad />
      <div ref={contactFormRef}>
        <ContactForm />
      </div>
      <Footer footer={data.footer} />
    </main>
  );
}
