import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "./logo.module.css";

export default function Logo({ white = true }) {
  // white es true si no se pasa como prop
  const navigate = useNavigate();

  function handleLogoClick() {
    navigate("/");
    window.scrollTo(0, 0);
  }

  return (
    <div className={styles.header}>
      <div className={styles.companyContainer} onClick={handleLogoClick}>
        <img loading="lazy"
          className={styles.company}
          src={
            white ? "assets/icons/LogoCierzoArcoirisWhite.svg" : "assets/icons/LogoCierzoArcoiris.svg"
          }
          alt="Company logo"
          style={{ cursor: "pointer" }}
        />
      </div>
    </div>
  );
}
