import styles from './pointAndText.module.css';

export default function PointAndText({text, fontSize = '16px', textColor, pointColor}) {

  // Obtener el valor de la variable CSS usando getComputedStyle si se requiere
  const cssVariableColor = getComputedStyle(document.documentElement).getPropertyValue('--custom-bg-color');

  return (
    <div className={styles.row}>
      <svg width="12" height="13" viewBox="0 0 12 13"
         style={{ fill: pointColor || cssVariableColor.trim() }}
         fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle id="Ellipse 31" cx="6" cy="6.79492" r="6" />
      </svg>
      <span className={styles.text} style={textColor ? {color: textColor, fontSize: fontSize} : {fontSize: fontSize}}>{text}</span>
    </div>
  );
}
