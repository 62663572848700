import ArrowButton from "../ArrowButton/ArrowButton";
import IconLabel from "../IconLabel/IconLabel";
import PointAndText from "../PointAndText/PointAndText";
import styles from "./findyourteam.module.css";
import { useNavigate } from "react-router-dom";


export default function FindYourTeam({ findYourTeam }) {

  const navigate = useNavigate();

  function handleSportClick(sportName) {
    console.log(`Navigating to details of ${sportName}`);
    navigate(`/${sportName.toLowerCase()}`);
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.header__title}>
            <PointAndText text="Deportes" pointColor="var(--color-accent)" />
            <h1>{findYourTeam.header.title}</h1>
          </div>
          <div className={styles.header__content}>
            <div className={styles.content__text}>
              <h3>{findYourTeam.header.subtitles[0]}</h3>
              <h3>{findYourTeam.header.subtitles[1]}</h3>
            </div>
            <div className={styles.content__button}>
              <IconLabel
                text={findYourTeam.header.button.text}
                backgroundColor={findYourTeam.header.button.backgroundColor}
                buttonBackgroundColor={findYourTeam.header.button.buttonBackgroundColor}
                textColor={findYourTeam.header.button.textColor}
                icon={
                  <img loading="lazy"
                    src={findYourTeam.header.button.iconSrc}
                    alt={findYourTeam.header.button.iconAlt}
                  />
                }
              />
            </div>
          </div>
        </div>
        <div className={styles.grid}>
          <div className={styles.grid__volley} onClick={() => handleSportClick("voley")}>
            <div className={styles.grid__button}>
              <ArrowButton
                bgColor={findYourTeam.sports[0].buttonBgColor}
                img={findYourTeam.sports[0].buttonIconSrc}
              />
            </div>
            <div className={styles.grid__text}>
              <h2>{findYourTeam.sports[0].name}</h2>
              <p>{findYourTeam.sports[0].description}</p>
            </div>
          </div>
          <div className={styles.grid__raquet} onClick={() => handleSportClick("deportes-de-raqueta")}>
            <div className={styles.grid__button}>
              <ArrowButton
                bgColor={findYourTeam.sports[1].buttonBgColor}
                img={findYourTeam.sports[1].buttonIconSrc}
              />
            </div>
            <div className={styles.grid__text}>
              <h2>{findYourTeam.sports[1].name}</h2>
              <p>{findYourTeam.sports[1].description}</p>
            </div>
          </div>
          <div className={styles.grid__basket} onClick={() => handleSportClick("baloncesto")}>
            <div className={styles.grid__button}>
              <ArrowButton
                bgColor={findYourTeam.sports[2].buttonBgColor}
                img={findYourTeam.sports[2].buttonIconSrc}
              />
            </div>
            <div className={styles.grid__text}>
              <h2>{findYourTeam.sports[2].name}</h2>
              <p>{findYourTeam.sports[2].description}</p>
            </div>
          </div>
          <div className={styles.grid__football} onClick={() => handleSportClick("futbol-sala")}>
            <div className={styles.grid__button}>
              <ArrowButton
                bgColor={findYourTeam.sports[3].buttonBgColor}
                img={findYourTeam.sports[3].buttonIconSrc}
              />
            </div>
            <div className={styles.grid__text}>
              <h2>{findYourTeam.sports[3].name}</h2>
              <p>{findYourTeam.sports[3].description}</p>
            </div>
          </div>
          <div className={styles.grid__freeTime} onClick={() => handleSportClick("tiempo-libre")}>
            <div className={styles.grid__button}>
              <ArrowButton
                bgColor={findYourTeam.sports[4].buttonBgColor}
                img={findYourTeam.sports[4].buttonIconSrc}
              />
            </div>
            <div className={styles.grid__text}>
              <h2>{findYourTeam.sports[4].name}</h2>
              <p>{findYourTeam.sports[4].description}</p>
            </div>
          </div>
        </div>
        <div className={styles.mobile__button}>
          <IconLabel
            text={findYourTeam.mobileButton.text}
            backgroundColor={findYourTeam.mobileButton.backgroundColor}
            buttonBackgroundColor={findYourTeam.mobileButton.buttonBackgroundColor}
            textColor={findYourTeam.mobileButton.textColor}
            icon={
              <img loading="lazy"
                src={findYourTeam.mobileButton.iconSrc}
                alt={findYourTeam.mobileButton.iconAlt}
              />
            }
          />
        </div>
      </div>
    </>
  );
}
