import Button from "../Button/Button";
import Card from "../Card/Card";
import IconLabel from "../IconLabel/IconLabel";
import styles from "./home.module.css";

export default function Home({ data, children }) {
  const {
    company__logo__src,
    company__logo__alt,
    card__background__color,
    title,
    body,
    icon__label__text,
    icon__label__background__color,
    icon__label__button__background__color,
    icon__label__text__color,
    arrow__down__icon__src,
    arrow__down__icon__alt,
  } = data;
  return (
    <div className={styles.home}>
      <div className={styles.header}>
        <img loading="lazy"
          className={styles.company}
          src={company__logo__src}
          alt={company__logo__alt}
        />
      </div>
      <div className={styles.home__main}>
        <div className={styles.home__text}>
          <h1>A.D. CIERZO prolgtb+</h1>
          <p>
            Donde todos pueden practicar deporte en un ambiente de respeto y
            libertad
          </p>
        </div>
        <Card backgroundColor={card__background__color}>
          <div className={styles.footer}>
            <div className={styles.title}>{title}</div>
            <div className={styles.body}>{body}</div>
            <IconLabel
              text={icon__label__text}
              backgroundColor={icon__label__background__color}
              buttonBackgroundColor={icon__label__button__background__color}
              textColor={icon__label__text__color}
              icon={
                <img loading="lazy"
                  src={arrow__down__icon__src}
                  alt={arrow__down__icon__alt}
                />
              }
            />
          </div>
        </Card>
      </div>
    </div>
  );
}
