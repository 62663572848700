import styles from './iconLabel.module.css';

export default function IconLabel(props) {
  const { text, onClick, icon, backgroundColor, buttonBackgroundColor, textColor } = props;

  // Obtener el valor de la variable CSS usando getComputedStyle si se requiere
  const cssVariableColor = getComputedStyle(document.documentElement).getPropertyValue('--custom-bg-color');

  return (
    <button
      className={`${styles.button} ${styles.primary}`}
      onClick={onClick}
      style={{ backgroundColor: buttonBackgroundColor }} // Configurar el fondo del botón
    >
      <div
        className={styles.iconWrapper}
        style={{ backgroundColor: backgroundColor || cssVariableColor.trim() }} // Configurar el fondo del ícono
      >
        {icon}
      </div>
      <span className={styles.text} style={{ color: textColor }}> {/* Configurar el color del texto */}
        {text}
      </span>
    </button>
  );
}
