// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDkj1qXNNCUKTRy6qo55041MtRZ0TckycQ",
  authDomain: "cierzo-login.firebaseapp.com",
  projectId: "cierzo-login",
  storageBucket: "cierzo-login.firebasestorage.app",
  messagingSenderId: "293553014015",
  appId: "1:293553014015:web:48be2002724c49bf5cb8ca",
  measurementId: "G-39ZDNT8T62"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app); // Para autenticación de usuarios
export const db = getFirestore(app); // Para acceso a Firestore
export default app;
