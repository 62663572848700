import Banner from "../components/Banner/Banner";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import Backgrounds from "../components/Backgrounds/Backgrounds";
import ContactForm from "../components/ContactForm/ContactForm";
import JuegosADCierzo from "../components/JuegosADCierzo/JuegosADCierzo";
import AboutUs from "../components/AboutUs/AboutUsPage";
import Logo from "../components/Logo/Logo";
import { useRef } from "react";

export default function AboutUsPage({ data }) {

  const contactFormRef = useRef(null);

  const scrollToContactForm = () => {
    contactFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <main>
      <Backgrounds imageUrl={"/assets/webImages/AboutUsBG.webp"}/>
      <Logo />
      <Banner data={data.banner} />
      <Header onContactClick={scrollToContactForm}  />
      <AboutUs data={data.aboutUsPage} />
      <JuegosADCierzo juegosADCierzo={data.juegosADCierzo} />
      <div ref={contactFormRef}>
        <ContactForm />
      </div>
      <Footer footer={data.footer} />
    </main>
  );
}
